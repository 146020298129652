import * as yup from 'yup';

import { Box, Typography } from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import { ROUTE_FORGOT_PASSWORD, ROUTE_REGISTRATION } from '../../navigation/routes';
import { Login } from '../../shared/dtos/login.interface';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  ApiError,
  AUTH_BLOCKED,
  AUTH_DELETED,
  AUTH_NOT_VERIFIED,
  AUTH_UNAUTHORIZED,
  USER_NOT_PAID,
} from '@jupiter/shared';
import { makeStyles } from '@material-ui/core/styles';
import { SerializedError } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { useEffect } from 'react';
import pablitaImage from '../../assets/pablita-867.png';
import AppTitle from '../../components/app-title';
import FormTextField from '../../components/forms/form-text-field';
import LoadingButton from '../../components/forms/loading-button';
import VBox from '../../components/layout/vbox';
import Hyperlink from '../../components/navigation/hyperlink';
import useNavigation from '../../navigation/nav.hook';
import { useLoginMutation } from '../../redux/authentication/authentication.api';
import useAuthentication from '../../redux/authentication/authentication.hooks';

const useStyles = makeStyles((theme) => ({
  grid: {
    height: '100%',
  },
}));

function LoginError({ error }: { error?: SerializedError | FetchBaseQueryError | undefined }) {
  if (!error) return null;

  let message = 'Ocorreu um erro inesperado';

  if ('status' in error) {
    const status = error.status;
    const data = error.data as ApiError;

    if (status === 400 || (status === 401 && data?.type === AUTH_UNAUTHORIZED)) {
      message = 'Credenciais inválidas';
    } else if (data?.type === AUTH_NOT_VERIFIED) {
      message = 'Ainda não confirmaste o teu e-mail';
    } else if (data?.type === USER_NOT_PAID) {
      message = 'Pagamento da subscrição em falta. Contacta-nos para o rectificar.';
    } else if (data?.type === AUTH_BLOCKED) {
      message = 'Conta bloqueada. Contacta-nos para mais detalhes.';
    } else if (data?.type === AUTH_DELETED) {
      message = 'Esta conta foi fechada. Contacta-nos para mais detalhes.';
    }
  }

  return (
    <Typography variant="body1" component="p" color="error">
      {message}
    </Typography>
  );
}

const schema = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().required(),
  })
  .required();

export default function LoginPage() {
  const classes = useStyles();
  const methods = useForm<Login>({ resolver: yupResolver(schema) });
  const { isLoggedIn, setLoggedIn } = useAuthentication();
  const [login, { isLoading, error }] = useLoginMutation();
  const { navToHome } = useNavigation();

  useEffect(() => {
    if (isLoggedIn) {
      navToHome();
    }
  }, [isLoggedIn, navToHome]);

  const onSubmit = async (data: Login) => {
    try {
      await login(data).unwrap();
      setLoggedIn(true);
    } catch (e) {
      // Already handled
    }
  };

  return (
    <Box
      width={{ xs: '100%', sm: '100%', md: 376 }}
      height={{ xs: '100%', sm: '100%', md: 'auto' }}
      mx="auto"
      my={{ xs: 'auto', sm: 'auto', md: 0 }}
      paddingTop={{ xs: '28px', sm: '28px', md: '10vh' }}
    >
      <VBox spacing={0} className={classes.grid}>
        <VBox spacing={2}>
          <Box display="flex" justifyContent="center">
            <Box borderRadius={26} paddingX={2.5} paddingY={1.5} bgcolor="secondary.main" boxShadow={1}>
              <AppTitle />
            </Box>
          </Box>
          <Box display="flex" justifyContent="center">
            <img src={pablitaImage} alt="Main" />
          </Box>
          <Box textAlign="center" padding="20px">
            <Typography variant="h6">Todo o teu negócio, uma só plataforma</Typography>
          </Box>
        </VBox>
        <Box
          height="100%"
          textAlign="center"
          paddingY={2.5}
          paddingX={4.5}
          borderRadius={{ xs: '20px 20px 0 0', sm: '20px 20px 0 0', md: '20px 20px 20px 20px' }}
          boxShadow={{ xs: 1, sm: 1, md: 0 }}
          bgcolor="secondary.main"
        >
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <VBox spacing={2}>
                <FormTextField name="email" type="text" label="E-mail" fullWidth />
                <Box>
                  <FormTextField name="password" type="password" label="Palavra-passe" fullWidth />
                  <Box mt={1} textAlign="right">
                    <Typography variant="body2" component="p" color="textPrimary">
                      <Hyperlink path={ROUTE_FORGOT_PASSWORD}>Esqueci-me da palavra-passe</Hyperlink>
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <LoginError error={error} />
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    isLoading={methods.formState.isSubmitting || isLoading}
                  >
                    Iniciar Sessão
                  </LoadingButton>
                </Box>
              </VBox>
            </form>
          </FormProvider>
          <Box marginTop={4.25}>
            <Typography variant="body2" component="p" color="textPrimary">
              Ainda não tens uma conta? <Hyperlink path={ROUTE_REGISTRATION}>Regista-te</Hyperlink>
            </Typography>
          </Box>
        </Box>
      </VBox>
    </Box>
  );
}
