import {
  GreenReceiptInvoiceQuery,
  GreenReceiptInvoiceReceiptQuery,
  GreenReceiptsReceiptQuery,
} from '../../shared/dtos/green-receipt.interface';
import { InvoiceList, InvoiceParams, InvoicesImport } from '../../shared/dtos/invoices.interface';
import { ProductResponse } from '../../shared/dtos/product.interface';

import { api } from '../api';

// This values will be changed in the future when we implement infinite scrolling
const productsPerPage = 1000;
const page = 1;

export const invoicesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    importInvoices: builder.mutation<void, InvoicesImport>({
      query: (body) => ({
        url: `/invoices/import`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Invoices', 'Taxes', 'Dashboard', 'IRS', 'SS'],
    }),
    getInvoices: builder.query<InvoiceList, InvoiceParams>({
      query: (params) => ({
        url: `/invoices`,
        params,
      }),
      providesTags: ['Invoices'],
    }),
    getInvoicesItem: builder.query<ProductResponse[], string | undefined>({
      query: (param) => ({
        url: `/products?per_page=${productsPerPage}&page=${page}&client_name=${param}`,
      }),
    }),
    getInvoicesFromFinancePortal: builder.query<any, void>({
      query: () => ({
        url: 'invoices/green_receipts/documents?offset=0&table_size=50',
      }),
    }),
    createVendusInvoice: builder.mutation<void, any>({
      query: (body) => {
        return {
          url: '/invoices?client_name=vendus',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Invoices', 'Taxes', 'Dashboard', 'IRS', 'SS'],
    }),
    createMoloniInvoice: builder.mutation<void, any>({
      query: (body) => {
        return {
          url: '/invoices?client_name=moloni',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Invoices', 'Taxes', 'Dashboard', 'IRS', 'SS'],
    }),
    createInvoiceXpress: builder.mutation<void, any>({
      query: (body) => {
        return {
          url: '/invoices?client_name=invoicexpress',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Invoices', 'Taxes', 'Dashboard', 'IRS', 'SS'],
    }),
    createGreenReceiptInvoice: builder.mutation<void, GreenReceiptInvoiceQuery>({
      query: (body) => {
        return {
          url: '/invoices/green_receipts_invoices',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Invoices', 'Taxes', 'Dashboard', 'IRS', 'SS'],
    }),
    createGreenReceiptInvoiceReceipt: builder.mutation<void, GreenReceiptInvoiceReceiptQuery>({
      query: (body) => {
        return {
          url: '/invoice_receipts/green_receipts_invoice_receipt',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Invoices', 'Taxes', 'Dashboard', 'IRS', 'SS'],
    }),
    createGreenReceiptsReceipt: builder.mutation<void, GreenReceiptsReceiptQuery>({
      query: (body) => {
        return {
          url: '/receipts/green_receipts',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Invoices', 'Taxes', 'Dashboard', 'IRS', 'SS'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetInvoicesQuery,
  useImportInvoicesMutation,
  useGetInvoicesItemQuery,
  useGetInvoicesFromFinancePortalQuery,
  useLazyGetInvoicesFromFinancePortalQuery,
  useCreateVendusInvoiceMutation,
  useCreateMoloniInvoiceMutation,
  useCreateInvoiceXpressMutation,
  useCreateGreenReceiptInvoiceMutation,
  useCreateGreenReceiptInvoiceReceiptMutation,
  useCreateGreenReceiptsReceiptMutation,
} = invoicesApi;
