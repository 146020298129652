import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
} from '@material-ui/core';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Can } from '../../providers/ability.provider';
import { NavItem } from '../nav.interfaces';

interface NavListItemProps {
  item: NavItem;
  className?: string;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.text.primary,
    '&$selected': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px',
      '&:hover': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        borderRadius: '8px',
      },
    },
    '&:hover': {
      backgroundColor: theme.palette.grey,
      borderRadius: '8px',
    },
  },
  selected: {},
  icon: ({ selected }: { selected: boolean }) => ({
    color: selected ? theme.palette.common.white : theme.palette.text.primary,
    minWidth: '35px',
  }),
  text: {
    minWidth: '141px',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  chip: {
    color: '#FFFFFF',
    backgroundColor: '#FF672F',
    borderRadius: '50px',
    paddingLeft: '5px',
    paddingRight: '5px',
    height: '16px',
    fontSize: '8px',
    textAlign: 'center',
    lineHeight: '16px',
    textTransform: 'uppercase',
  },
}));

function NavItemContent(props: NavListItemProps & { selected?: boolean }) {
  const { selected, item, disabled } = props;
  const { icon, title } = item;
  const classes = useStyles({ selected: selected ?? false });

  return (
    <>
      <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
      <ListItemText primary={title} primaryTypographyProps={{ variant: 'body1' }} className={classes.text} />
      {disabled && (
        <ListItemSecondaryAction>
          <div className={classes.chip}>Carpo</div>
        </ListItemSecondaryAction>
      )}
    </>
  );
}

function NavExternalLinkItem(props: NavListItemProps) {
  const { className, item } = props;
  const { path } = item;
  const classes = useStyles({ selected: false });

  return (
    <ListItem
      button
      component={'a'}
      classes={{
        root: classes.root,
      }}
      className={className}
      href={path}
      target="_blank"
    >
      <NavItemContent {...props} />
    </ListItem>
  );
}

function NavExpandItem(props: NavListItemProps) {
  const { className, item } = props;
  const { disabled, children } = item;
  const classes = useStyles({ selected: false });
  const [open, setOpen] = useState<boolean>(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem
        button
        classes={{
          root: classes.root,
        }}
        onClick={handleClick}
        className={className}
        disabled={disabled}
      >
        <NavItemContent {...props} />
        {children?.length ? (
          open !== undefined && open !== null && open ? (
            <ExpandLessIcon />
          ) : (
            <ExpandMoreIcon />
          )
        ) : null}
      </ListItem>
      {children?.length && (
        <Collapse in={open ?? false} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.children?.map((child) => <NavListItem key={child.title} item={child} className={classes.nested} />)}
          </List>
        </Collapse>
      )}
    </>
  );
}

function NavRouteItem(props: NavListItemProps) {
  const { className, item, disabled } = props;
  const { path } = item;
  const currentLocation = useLocation();
  const navigate = useNavigate();
  const selected = currentLocation.pathname === path;
  const classes = useStyles({ selected });

  const handleClick = () => {
    if (path) {
      navigate(path);
    }
  };

  return (
    <ListItem
      button
      selected={selected}
      classes={{
        root: classes.root,
        selected: classes.selected,
      }}
      onClick={handleClick}
      className={className}
      disabled={disabled}
    >
      <NavItemContent {...props} selected={selected} />
    </ListItem>
  );
}

export default function NavListItem(props: NavListItemProps) {
  const { item, disabled } = props;
  const { children, isExternal, subject, action } = item;

  const hasChildren = children && children.length > 0;

  if (hasChildren) {
    return <NavExpandItem {...props} />;
  } else if (isExternal) {
    return <NavExternalLinkItem {...props} />;
  }

  if (subject) {
    return (
      <Can I={action ?? 'manage'} a={subject as any} passThrough>
        {(allowed) => <NavRouteItem {...props} disabled={!allowed} />}
      </Can>
    );
  }

  return <NavRouteItem {...props} disabled={disabled} />;
}
