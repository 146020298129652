import { NavItem, NavSection } from './nav.interfaces';
import {
  blogLink,
  calendarRoute,
  dashboardRoute,
  expensesRoute,
  integrationsRoute,
  invoicesRoute,
  legalLink,
  newInvoiceRoute,
  partnersRoute,
  taxDetailsRoute,
  taxesIrsRoute,
  taxesSocialSecurityRoute,
  taxesVatRoute,
} from './routes';

import AccessibilityIcon from '@material-ui/icons/Accessibility';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DescriptionIcon from '@material-ui/icons/Description';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import MenuBookOutlinedIcon from '@material-ui/icons/MenuBookOutlined';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import ReceiptIcon from '@material-ui/icons/Receipt';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import { FeatureType } from '../common/helpers/billing.helpers';
import { useGetProfileQuery } from '../redux/authentication/authentication.api';
import NavAppTitle from './components/nav-app-title';
import NavListItem from './components/nav-list-item';
import NavListSection from './components/nav-list-section';
import NavUser from './components/nav-user';

const createSidebar = (features: FeatureType[]): NavSection[] => {
  const controlPanel: NavItem[] = [];
  const invoicePanel: NavItem[] = [];
  const taxesPanel: NavItem[] = [];

  invoicePanel.push({
    title: invoicesRoute.title,
    path: invoicesRoute.path,
    icon: <AssignmentOutlinedIcon />,
  });

  invoicePanel.push({
    title: newInvoiceRoute.title,
    path: newInvoiceRoute.path,
    icon: <AddCircleOutlineOutlinedIcon />,
    action: 'create',
    subject: 'Invoice',
  });

  if (features.includes('f_dashboard')) {
    controlPanel.push({
      title: dashboardRoute.title,
      path: dashboardRoute.path,
      icon: <DonutLargeIcon />,
    });
  }

  if (features.includes('f_expense_list')) {
    controlPanel.push({
      title: expensesRoute.title,
      path: expensesRoute.path,
      icon: <LocalAtmIcon />,
    });
  }

  if (features.includes('f_invoice_list')) {
    controlPanel.push({
      title: 'Faturas',
      icon: <ReceiptIcon />,
      path: invoicesRoute.path,
      children: invoicePanel,
    });
  }

  if (features.includes('f_tax_prediction')) {
    controlPanel.push({
      title: 'Impostos',
      icon: <ShowChartIcon />,
      path: taxesIrsRoute.path,
      children: taxesPanel,
    });

    taxesPanel.push({
      title: taxesIrsRoute.title,
      path: taxesIrsRoute.path,
      icon: <MonetizationOnIcon />,
      subject: 'IRS',
    });

    taxesPanel.push({
      title: taxesVatRoute.title,
      path: taxesVatRoute.path,
      icon: <ReceiptIcon />,
      subject: 'VAT',
    });

    taxesPanel.push({
      title: taxesSocialSecurityRoute.title,
      path: taxesSocialSecurityRoute.path,
      icon: <AccessibilityIcon />,
      subject: 'SS',
    });
  }

  if (features.includes('f_calendar')) {
    controlPanel.push({
      title: calendarRoute.title,
      path: calendarRoute.path,
      icon: <CalendarTodayIcon />,
    });
  }

  /*,
    {
      title: 'Carteira',
      icon: <AccountBalanceWalletIcon />,
    },
    {
      title: 'Banco',
      icon: <AccountBalanceIcon />,
    }
  );*/

  const other: NavItem[] = [];

  other.push({
    title: taxDetailsRoute.title,
    path: taxDetailsRoute.path,
    icon: <RecentActorsIcon />,
  });

  other.push({
    title: integrationsRoute.title,
    path: integrationsRoute.path,
    icon: <DashboardIcon />,
    subject: 'Integration',
  });

  /*other.push(
    {
      title: 'Comunidade',
      icon: <PeopleIcon />,
    },
    {
      title: 'Notícias',
      icon: <WebIcon />,
    }
  );*/

  other.push({
    title: partnersRoute.title,
    path: partnersRoute.path,
    icon: <PeopleAltIcon />,
  });

  other.push({
    title: blogLink.title,
    path: blogLink.href,
    icon: <MenuBookOutlinedIcon />,
    isExternal: true,
  });

  other.push({
    title: legalLink.title,
    path: legalLink.href,
    icon: <DescriptionIcon />,
    isExternal: true,
  });

  return [
    {
      title: 'Painel de Controlo',
      children: controlPanel,
    },
    {
      title: 'Outros',
      children: other,
    },
  ];
};

export default function Sidebar() {
  const { data } = useGetProfileQuery();

  const sidebar = createSidebar((data?.plan.features as FeatureType[] | undefined) ?? []);

  return (
    <>
      <NavListSection divider={true} padding="normal">
        <NavAppTitle />
      </NavListSection>
      <NavListSection divider={true}>
        <NavUser />
      </NavListSection>
      {sidebar.map((section, i) => (
        <NavListSection key={section.title} divider={i < sidebar.length - 1} title={section.title}>
          {section.children.map((item, i) => (
            <NavListItem key={item.title} item={item} />
          ))}
        </NavListSection>
      ))}
    </>
  );
}
