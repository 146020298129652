import { unpackRules } from '@casl/ability/extra';
import { createContextualCan, useAbility } from '@casl/react';
import { createContext, ReactNode, useEffect, useState } from 'react';

import { createMongoAbility } from '@casl/ability';
import { AppAbility } from '@jupiter/shared';
import { useGetProfileQuery } from '../redux/authentication/authentication.api';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const AbilityContext = createContext<AppAbility>(undefined!);

export const AbilityProvider = ({ children }: { children: ReactNode }) => {
  const [ability, setAbility] = useState<AppAbility>(createMongoAbility<AppAbility>());
  const { data } = useGetProfileQuery();

  useEffect(() => {
    const newAbility = createMongoAbility<AppAbility>();

    if (data?.rules) {
      newAbility.update(unpackRules(data.rules));
    }

    setAbility(newAbility);
  }, [data]);

  return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
};

export const Can = createContextualCan(AbilityContext.Consumer);

export const useAbilityContext = () => {
  const contextValue = useAbility(AbilityContext);

  if (!contextValue) {
    throw new Error('AbilityContext value was not provided');
  }

  return contextValue;
};
