import { createSlice } from '@reduxjs/toolkit';
import { SynchronizationDialogState } from './synchronization-dialog.interfaces';

const initialState: SynchronizationDialogState = {
  isOpen: false,
};

const synchronizationDialogSlice = createSlice({
  name: 'synchronizationDialog',
  initialState,
  reducers: {
    open: (state) => {
      state.isOpen = true;
    },
    close: (state) => {
      state.isOpen = false;
    },
  },
});

export const { open, close } = synchronizationDialogSlice.actions;

export default synchronizationDialogSlice.reducer;
