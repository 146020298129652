import { SynchronizationStatus, TriggerSynchronization } from '@jupiter/shared';
import { api } from '../api';

export const synchronizationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    triggerSynchronization: builder.mutation<void, TriggerSynchronization>({
      query: (params: TriggerSynchronization) => ({
        url: 'synchronization/trigger',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['IRS', 'Taxes', 'Invoices', 'Expenses', 'SS'],
    }),
    isSynchronizationRunning: builder.query<SynchronizationStatus, void>({
      query: () => 'synchronization/is-running',
    }),
  }),
  overrideExisting: false,
});

export const { useTriggerSynchronizationMutation, useIsSynchronizationRunningQuery } = synchronizationApi;
