import {
  IrsParams,
  IrsPrediction,
  IrsStatement,
  MonthlyParams,
  MonthlyTaxes,
  QuarterParams,
  QuarterTaxes,
  SSParams,
  SSPrediction,
  Taxes,
} from '@jupiter/shared';

import { api } from '../api';

export const taxesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTaxes: builder.query<Taxes, void>({
      query: () => ({
        url: 'taxes',
      }),
      providesTags: ['Taxes'],
    }),
    getQuarterTaxes: builder.query<QuarterTaxes, QuarterParams>({
      query: (params: QuarterParams) => ({
        url: 'taxes/quarter',
        params,
      }),
      providesTags: ['Taxes'],
    }),
    getMonthlyTaxes: builder.query<MonthlyTaxes, MonthlyParams>({
      query: (params: MonthlyParams) => ({
        url: 'taxes/monthly',
        params,
      }),
      providesTags: ['Taxes'],
    }),
    getIrsPrediction: builder.query<IrsPrediction, IrsParams>({
      query: (params: IrsParams) => ({
        url: 'taxes/irs',
        params,
      }),
      providesTags: ['IRS'],
    }),
    getStatements: builder.query<IrsStatement[], void>({
      query: () => ({
        url: 'taxes/irs/statements',
      }),
      providesTags: ['IRS'],
    }),
    getSSPrediction: builder.query<SSPrediction, SSParams>({
      query: (params: SSParams) => ({
        url: 'taxes/social-security',
        params,
      }),
      providesTags: ['SS'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTaxesQuery,
  useGetQuarterTaxesQuery,
  useGetMonthlyTaxesQuery,
  useGetIrsPredictionQuery,
  useLazyGetIrsPredictionQuery,
  useGetStatementsQuery,
  useGetSSPredictionQuery,
} = taxesApi;
