import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Plan } from '../../shared/dtos/user.interface';
import { PlansState } from './plans.interfaces';

const initialState: PlansState = {
  isChangePlanOpen: false,
  targetPlan: null,
};

export const plansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    openChangePlanDialog: (state, { payload }: PayloadAction<Plan>) => {
      state.isChangePlanOpen = true;
      state.targetPlan = payload;
    },
    closeChangePlanDialog: (state) => {
      state.isChangePlanOpen = false;
      state.targetPlan = null;
    },
  },
});

export default plansSlice.reducer;
