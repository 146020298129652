import { lazy } from 'react';
import ForgotPasswordPage from '../pages/forgot-password/forgot-password.page';
import LoginPage from '../pages/login/login.page';
import PasswordResetPage from '../pages/password-reset/password-reset.page';
import RegistrationPage from '../pages/registration/registration.page';
import VerifyPage from '../pages/verify/verify.page';
import { Can } from '../providers/ability.provider';
import { NavExternalLink, NavRoute } from './nav.interfaces';

const AccountPage = lazy(() => import('../pages/account/account.page'));
const CalendarPage = lazy(() => import('../pages/calendar/calendar.page'));
const DashboardPage = lazy(() => import('../pages/dashboard/dashboard.page'));
const ExpensesPage = lazy(() => import('../pages/expenses/expenses.page'));
const InvoicesPage = lazy(() => import('../pages/invoices/invoices.page'));
const NewInvoicePage = lazy(() => import('../pages/new-invoice/new-invoice.page'));
const IntegrationsPage = lazy(() => import('../pages/integrations/integrations.page'));
const PartnersPage = lazy(() => import('../pages/partners/partners.page'));
const TaxDetailsPage = lazy(() => import('../pages/tax-details/tax-details.page'));
const GreenReceiptsReceiptPage = lazy(() => import('../pages/green-receipts-receipt/green-receipts-receipt.page'));
const TaxesIrsPage = lazy(() => import('../pages/taxes/taxes-irs.page'));
const TaxesIrsV2Page = lazy(() => import('../pages/taxes/taxes-irs-v2.page'));
const TaxesVatPage = lazy(() => import('../pages/taxes/taxes-vat.page'));
const TaxesSocialSecurityPage = lazy(() => import('../pages/taxes/taxes-social-security.page'));
const TaxesSocialSecurityV2Page = lazy(() => import('../pages/taxes/taxes-social-security-v2.page'));

export const ROUTE_REGISTRATION = '/registration';
export const ROUTE_LOGIN = '/login';
export const ROUTE_FORGOT_PASSWORD = '/forgot-password';
export const ROUTE_PASSWORD_RESET = '/password-reset';
export const ROUTE_VERIFY = '/verify';
export const ROUTE_HOME = '/';
export const ROUTE_EXPENSES = '/expenses';
export const ROUTE_INVOICES = '/invoices';
export const ROUTE_AUTOMATED_INVOICES = '/automated-invoices';
export const ROUTE_NEW_INVOICE = '/new-invoice';
export const ROUTE_TAXES_IRS = '/taxes/irs';
export const ROUTE_TAXES_VAT = '/taxes/sales';
export const ROUTE_TAXES_SOCIAL_SECURITY = '/taxes/social-security';
export const ROUTE_ACCOUNT = '/account';
export const ROUTE_INTEGRATIONS = '/integrations';
export const ROUTE_CALENDAR = '/calendar';
export const ROUTE_HELP = '/help';
export const ROUTE_TAX_DETAILS = '/tax-details';
export const ROUTE_PARTNERS = '/partners';
export const ROUTE_GREEN_RECEIPTS_RECEIPT = '/invoices/green-receipts';
export const LINK_TERMS = 'https://jupiterapp.pt/termos-e-condicoes/';
export const LINK_BLOG = 'https://jupiterapp.pt/blog/';

export type AccountTab = 'billing' | 'profile';

export const loginRoute: NavRoute = {
  title: 'Login',
  path: ROUTE_LOGIN,
  component: <LoginPage />,
};

export const registrationRoute: NavRoute = {
  title: 'Registo',
  path: ROUTE_REGISTRATION,
  component: <RegistrationPage />,
};

export const verifyRoute: NavRoute = {
  title: 'Verify',
  path: ROUTE_VERIFY,
  component: <VerifyPage />,
};

export const forgotPasswordRoute: NavRoute = {
  title: 'Recuperação de Conta',
  path: ROUTE_FORGOT_PASSWORD,
  component: <ForgotPasswordPage />,
};

export const resetPasswordRoute: NavRoute = {
  title: 'Alteração de Palavra-Passe',
  path: ROUTE_PASSWORD_RESET,
  component: <PasswordResetPage />,
};

export const dashboardRoute: NavRoute = {
  title: 'Visão Geral',
  path: ROUTE_HOME,
  component: <DashboardPage />,
};

export const expensesRoute: NavRoute = {
  title: 'Despesas',
  path: ROUTE_EXPENSES,
  component: <ExpensesPage />,
};

export const invoicesRoute: NavRoute = {
  title: 'Todas',
  path: ROUTE_INVOICES,
  component: <InvoicesPage />,
};

export const newInvoiceRoute: NavRoute = {
  title: 'Nova Fatura',
  path: ROUTE_NEW_INVOICE,
  component: <NewInvoicePage />,
};

export const taxesIrsRoute: NavRoute = {
  title: 'IRS',
  path: ROUTE_TAXES_IRS,
  component: (
    <>
      <Can I="manage" a="Pioneers">
        <TaxesIrsV2Page />
      </Can>
      <Can not I="manage" a="Pioneers">
        <TaxesIrsPage />
      </Can>
    </>
  ),
};

export const taxesVatRoute: NavRoute = {
  title: 'IVA',
  path: ROUTE_TAXES_VAT,
  component: <TaxesVatPage />,
};

export const taxesSocialSecurityRoute: NavRoute = {
  title: 'Segurança Social',
  path: ROUTE_TAXES_SOCIAL_SECURITY,
  component: (
    <>
      <Can I="manage" a="Experimental">
        <TaxesSocialSecurityV2Page />
      </Can>
      <Can not I="manage" a="Experimental">
        <TaxesSocialSecurityPage />
      </Can>
    </>
  ),
};

export const accountRoute: NavRoute = {
  title: 'Conta',
  path: ROUTE_ACCOUNT,
  component: <AccountPage />,
  showAlways: true,
};

export const taxDetailsRoute: NavRoute = {
  title: 'Dados Fiscais',
  path: ROUTE_TAX_DETAILS,
  component: <TaxDetailsPage />,
};

export const integrationsRoute: NavRoute = {
  title: 'Integrações',
  path: ROUTE_INTEGRATIONS,
  component: <IntegrationsPage />,
};

export const calendarRoute: NavRoute = {
  title: 'Calendário Fiscal',
  path: ROUTE_CALENDAR,
  component: <CalendarPage />,
};

export const partnersRoute: NavRoute = {
  title: 'Parceiros',
  path: ROUTE_PARTNERS,
  component: <PartnersPage />,
};

export const blogLink: NavExternalLink = {
  title: 'Blog',
  href: LINK_BLOG,
};

export const legalLink: NavExternalLink = {
  title: 'Termos & Condições',
  href: LINK_TERMS,
};

export const greenReceiptsReceiptRoute: NavRoute = {
  title: 'Emitir Recibo',
  path: ROUTE_GREEN_RECEIPTS_RECEIPT,
  component: <GreenReceiptsReceiptPage />,
};

export const privateRoutes: NavRoute[] = [
  dashboardRoute,
  expensesRoute,
  invoicesRoute,
  taxesIrsRoute,
  taxesVatRoute,
  taxesSocialSecurityRoute,
  accountRoute,
  integrationsRoute,
  calendarRoute,
  taxDetailsRoute,
  partnersRoute,
  newInvoiceRoute,
  greenReceiptsReceiptRoute,
];

export const publicRoutes: NavRoute[] = [
  loginRoute,
  registrationRoute,
  verifyRoute,
  forgotPasswordRoute,
  resetPasswordRoute,
];
