import { Alert, AlertTitle } from '@material-ui/lab';

import { Button } from '@material-ui/core';
import useNavigation from '../../navigation/nav.hook';

export default function PaymentAlert() {
  const { navToAccount } = useNavigation();

  return (
    <Alert
      severity="error"
      action={
        <Button color="inherit" size="small" onClick={() => navToAccount('billing')}>
          Alterar
        </Button>
      }
    >
      <AlertTitle>Aviso</AlertTitle>A aguardar o pagamento da subscrição. Entretanto, terás apenas acesso às
      funcionalidades grátis. Para corrigir a situação, altera o teu método de pagamento.
    </Alert>
  );
}
