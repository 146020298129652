export function toDecimal(value: number, places = 2): number {
  return Number(Math.round(parseFloat(value + 'e' + places)) + 'e-' + places);
}

export function toCurrency(value?: number | string | null, currency = 'EUR', locale: Intl.LocalesArgument = 'pt-PT') {
  const finalValue = value ?? 0;
  return Number(finalValue).toLocaleString(locale, { currency, style: 'currency' });
}

export function toPercent(value?: number | string | null, locale: Intl.LocalesArgument = 'pt-PT') {
  const finalValue = value ?? 0;
  return Number(finalValue).toLocaleString(locale, { style: 'percent', maximumFractionDigits: 2 });
}

export function parseCurrencyString(value: string): number {
  // Remove the currency symbol and any whitespace
  const cleanString = value.replace(/[^\d,.-]/g, '');
  // Replace the thousands separator '.' with an empty string
  const noThousandsSeparator = cleanString.replace(/\./g, '');
  // Replace the decimal separator ',' with '.'
  const standardizedString = noThousandsSeparator.replace(/,/g, '.');
  // Convert the resulting string to a number
  return parseFloat(standardizedString);
}

export function toNumberOrDefault(value: any, defaultValue: number = 0): number {
  if (value === undefined || value === null) {
    return defaultValue;
  }

  if (typeof value === 'number') {
    return value;
  }

  const converted = Number(value);
  if (Number.isNaN(converted)) {
    return defaultValue;
  }

  return converted;
}
